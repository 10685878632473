<template>
  <div v-if="receiveManuscriptFlag" class="receiveManuscript">
		<div class="header">
			<div class="title">接收</div>
			<img @click="close" style="width: 15px;height:15px;cursor: pointer;" src="../../assets/images/close.png" />
		</div>
		<div class="content">
			<el-upload
				class="upload-demo"
				:headers="headers"
				:before-upload="beforeUpload"
				:action="baseURL+'/system/file/uploadFile'"
				:data="uploadParams"
				:limit="1"
				:on-success="(res, file, fileList)=>{uploadSuccess(res, file, fileList)}"
				:file-list="fileList">
				<el-button size="small" type="primary">上传修改文档</el-button>
			</el-upload>
		</div>
		<div class="footer">
			<div class="comfirn" @click="comfirn">确认</div>
		</div>
  </div>
</template>
<script>
import {receiveManuscript} from '../../api/userHome.js'
import {updateManuscript} from '../../api/home.js'
import { getToken } from '@/utils/auth'
export default {
	props: ['receiveManuscriptFlag', 'id', 'type'], //type: 1用户 2平台
  data() {
    return {
      headers: {
        Authorization: getToken()
      },
			baseURL: process.env.VUE_APP_BASE_API,
			// baseURL:'http://172.16.0.109:8070',
			//上传文件额外参数
      uploadParams: {
        fileClassify: 12,
        tempFlag: 1
      },
      fileList: [],
      contributeFileName: '',
      contributeUrl: ''
    }
  },
  created() {

  },
  methods: {
		// 更新临时文件标志
    async updateTempFlagFn() {
      let obj = {}
      obj.ids = this.uploadData[0].id
      obj.tempFlag = 0
      let res = await updateTempFlag(obj)
      console.log(res)
      if (res.code === 200) {
      }
    },
		// 文件上传前校验
    beforeUpload(file) {
      let fileName = file.name
      let lastIndex = fileName.lastIndexOf('.')
      let type = fileName.substring(lastIndex+1) // doc,docx,xls,xlsx,ppt,pptx,txt,pdf
      // if (this.form.type===2) {
      if (type !== 'doc' && type !== 'docx') {
        this.$message.error('暂不支持此文件格式，请检查后重新上传')
        return false;
      }
      // }
    },
    uploadSuccess(response,file,fileList) {
      this.contributeFileName = fileList[0].name
			this.contributeUrl = fileList[0].response.data.id
			this.fileList = fileList
    },
		async comfirn() {
			if(this.fileList.length==0) {
					this.$message({
						message: '请上传修改后的文档',
						type: 'error'
					});
					return
				}
			const params = {
				contributeFileName: this.contributeFileName,
				contributeUrl: this.contributeUrl,
				id: parseInt(this.id)
			}
      let res = {}
      if(this.type=='1') {
        res = await updateManuscript(params)
      } else {
        res = await receiveManuscript(params)
      }
			if(res.code == 200) {
        this.$message({
          message: '完成',
          type: 'success'
        });
				this.$emit('receiveManuscriptcomfirn')
				this.updateTempFlagFn()
			}
		},
		close() {
			this.$emit('receiveManuscriptClose')
		}
  },
}
</script>
<style lang="scss" scoped>
.receiveManuscript {
	//height: 320px;
	width: 500px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 8px 8px 0px 0px;
	border-radius: 8px 8px 0px 0px;
	background: #FFF;
	display: flex;
	flex-direction: column;
	z-index: 1000;
	.footer {
		display: flex;
		padding: 20px 20px 10px 20px;
		justify-content: center;
		align-items: center;
		flex: 0 0 60px;
		box-sizing: border-box;
		.comfirn {
			//width: 68px;
			cursor: pointer;
			height: 30px;
			border-radius: 4px;
			border: 1px solid #B9B9B9;
			background: #FFF;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px 20px;
			box-sizing: border-box;
			line-height: 14px;
			font-size: 14px;
			color: #747474;
		}
	}
	.content {
		padding: 10px 20px;
		flex: 1;
		display: flex;
	}
	.header {
		display: flex;
		padding: 20px 20px 10px 20px;
		justify-content: space-between;
		align-items: center;
		flex: 0 0 60px;
		box-sizing: border-box;
		.title {
			color: #262626;
			font-size: 20px;
			font-weight: bold;
			line-height: 30px;
		}
	}
}
</style>
